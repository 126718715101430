<template>
  <div class="page-wrapper">
    <div class="header">
      <div class="header-content">关于我们</div>
    </div>
    <div class="container">
      <div class="content" v-html="aboutContent"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getAboutUs } from "@/api/company.js";

const aboutContent = ref("");
const ossUrl = ref('https://admin.syshunying.cn');

onMounted(async () => {
  try {
    const res = await getAboutUs();
    if (res.code === 200) {
      // 使用正则表达式匹配图片的 src 属性，并添加前缀
      let contentWithPrefix = res.data.replace(/<img[^>]*src="([^"]*)"/g, (match, src) => {
        if (!src.startsWith('http')) {
          return match.replace(src, `${ossUrl.value}${src}`);
        }
        return match;
      });
      aboutContent.value = contentWithPrefix;
    }
  } catch (error) {
    console.error("获取关于我们内容失败:", error);
  }
});
</script>

<style scoped>
.page-wrapper {
  min-height: 100vh;
  background-color: #f5f7fa;
}

.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.container {
  width: 90%; /* 使用百分比宽度 */
  max-width: 800px; /* 设置最大宽度 */
  margin: 30px auto;
  padding: 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
}

.content {
  font-size: 16px;
  line-height: 1.8;
  color: #606266;
}

.content :deep(p) {
  margin-bottom: 1.2em;
  text-indent: 2em;
}

.content :deep(img.wscnph) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>