<template>
  <div>
    <div class="header">
      <div class="header-content">技术成果</div>
    </div>
    <div class="main-wrapper">
      <div class="main-container">
        <div class="tabs-section">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="技术成果" name="techResults"></el-tab-pane>
            <el-tab-pane label="技术需求" name="techNeeds"></el-tab-pane>
          </el-tabs>
        </div>

        <div class="button-section">
          <el-button @click="showDialog" type="primary">{{
            buttonLabel
          }}</el-button>
        </div>

        <div class="search-section">
          <el-input
            v-if="activeTab === 'techResults'"
            v-model="searchText"
            placeholder="搜索相关名称或领域"
            style="width: 100%; height: 40px"
            @keyup.enter="handleSearch"
          ></el-input>
          <el-input
            v-else
            v-model="searchText1"
            placeholder="搜索相关需求名称或领域"
            style="width: 100%; height: 40px"
            @keyup.enter="handleSearch"
          ></el-input>
        </div>

        <div class="summary-box">
          <img
            :src="
              activeTab === 'techResults'
                ? require('../assets/achLogo.png')
                : require('../assets/ach1.png')
            "
            alt="图标"
            class="summary-image"
          />
          <span class="summary-text">{{
            activeTab === "techResults" ? "技术成果" : "技术需求"
          }}</span>
          <span class="summary-count"
            >{{ activeTab === "techResults" ? total : total1 }}项</span
          >
        </div>
        <div class="list-container">
          <div class="list-content">
            <!-- 技术成果列表 -->
            <template v-if="activeTab === 'techResults'">
              <div v-for="item in techResults" :key="item.id" class="list-item">
                <p class="name">{{ item.name }}</p>
                <p class="info-item">
                  <img src="../assets/ach1.png" class="item-icon" />
                  技术领域: {{ item.area }}
                </p>
                <p class="info-item">
                  <img src="../assets/ach2.png" class="item-icon" />
                  所属机构: {{ item.institution }}
                </p>
                <p class="info-item">
                  <img src="../assets/ach3.png" class="item-icon" />
                  转化方式: {{ item.transform }}
                </p>
                <p class="info-item">
                  <img src="../assets/ach4.png" class="item-icon" />
                  所处阶段: {{ item.stage }}
                </p>

                <el-button
                  type="primary"
                  class="view-btn"
                  @click="achieveDetail(item.id)"
                  >查看详情</el-button
                >
              </div>
              <div class="pagination-wrapper">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  v-model:current-page="currentPage"
                  @update:current-page="handlePageChange"
                ></el-pagination>
              </div>
            </template>

            <!-- 技术需求列表 -->
            <template v-else>
              <div v-for="item in techNeeds" :key="item.id" class="list-item">
                <p class="name">{{ item.name }}</p>
                <p class="info-item">
                  <img src="../assets/ach1.png" class="item-icon" />
                  技术领域: {{ item.area }}
                </p>
                <p class="info-item">
                  <img src="../assets/ach2.png" class="item-icon" />
                  所属机构: {{ item.institution }}
                </p>
                <p class="info-item">
                  <img src="../assets/ach3.png" class="item-icon" />
                  需求类型: {{ item.transform }}
                </p>
                <p class="info-item">
                  <img src="../assets/ach4.png" class="item-icon" />
                  所处阶段: {{ item.stage }}
                </p>
                <el-button
                  type="primary"
                  class="view-btn"
                  @click="requireDetail(item.id)"
                  >查看详情</el-button
                >
              </div>
              <div class="pagination-wrapper">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total1"
                  v-model:current-page="currentPage1"
                  @update:current-page="handlePageChange1"
                ></el-pagination>
              </div>
            </template>
          </div>
        </div>

        <!-- 新增弹窗 -->
        <el-dialog
          v-model="dialogVisible"
          :title="'新增' + (activeTab === 'techResults' ? '成果' : '需求')"
        >
          <el-form :model="form" label-width="120px">
            <el-form-item label="名称" required>
              <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="领域" required>
              <el-input v-model="form.area" placeholder="请输入领域"></el-input>
            </el-form-item>
            <el-form-item label="机构" required>
              <el-input
                v-model="form.institution"
                placeholder="请输入机构名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="转化方式">
              <el-input
                v-model="form.transform"
                placeholder="请输入相关信息"
              ></el-input>
            </el-form-item>
            <el-form-item label=" 所处阶段">
              <el-input
                v-model="form.stage"
                placeholder="请输入相关信息"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细描述">
              <el-input
                v-model="form.desc"
                type="textarea"
                :rows="4"
                placeholder="请输入详细描述"
              ></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false">取消</el-button>
              <el-button type="primary" @click="submitForm">提交</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </div>

    <EndIcons />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { ElMessage } from "element-plus";
import {
  getAchieveList,
  getAchieveAdd,
  getRequirList,
  getRequirAdd,
} from "../api/achieve.js";
import EndIcons from "../components/EndIcons.vue";

import { useRouter } from "vue-router";
const router = useRouter();
// 响应式数据
const activeTab = ref("techResults");
const searchText = ref("");
const searchText1 = ref("");
const dialogVisible = ref(false);

// 技术成果相关
const techResults = ref([]);
const total = ref(0);
const currentPage = ref(1);

// 技术需求相关
const techNeeds = ref([]);
const total1 = ref(0);
const currentPage1 = ref(1);

// 表单数据
const form = ref({
  name: "",
  area: "",
  institution: "",
  transform: "",
  stage: "",
  desc: "",
});

// 计算属性
const buttonLabel = computed(() =>
  activeTab.value === "techResults" ? "新增成果" : "新增需求"
);

// 方法
const showDialog = () => {
  dialogVisible.value = true;
};
// 跳转需求详情页面
const requireDetail = (id) => {
  router.push({ name: "requireDetail", query: { id } });
};
const achieveDetail = (id) => {
  router.push({ name: "achieveDetail", query: { id } });
};
// 获取技术成果列表
const getList = async () => {
  try {
    const res = await getAchieveList({
      page: currentPage.value,
      size: 10,
      key: searchText.value,
    });
    if (res.data?.list) {
      techResults.value = res.data.list;
      total.value = res.data.total;
    }
  } catch (error) {
    ElMessage.error("获取数据失败");
  }
};

// 获取技术需求列表
const getList1 = async () => {
  try {
    const res = await getRequirList({
      page: currentPage1.value,
      size: 10,
      key: searchText1.value,
    });
    if (res.data?.list) {
      techNeeds.value = res.data.list;
      total1.value = res.data.total;
    }
  } catch (error) {
    ElMessage.error("获取需求数据失败");
  }
};

// 分页处理
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
  getList();
};

const handlePageChange1 = (newPage) => {
  currentPage1.value = newPage;
  getList1();
};

// 搜索处理
const handleSearch = () => {
  if (activeTab.value === "techResults") {
    currentPage.value = 1;
    getList();
  } else {
    currentPage1.value = 1;
    getList1();
  }
};

// 表单提交
const submitForm = async () => {
  try {
    const requiredFields = ["name", "area", "institution"];
    if (requiredFields.some((field) => !form.value[field])) {
      ElMessage.warning("请填写必填字段");
      return;
    }

    if (activeTab.value === "techResults") {
      const res = await getAchieveAdd(form.value);
      if (res.code === 200) {
        ElMessage.success("成果添加成功");
        dialogVisible.value = false;
        form.value = {
          name: "",
          area: "",
          institution: "",
          transform: "",
          stage: "",
          desc: "",
        };
        await getList();
      }
    } else {
      const res = await getRequirAdd(form.value);
      if (res.code === 200) {
        ElMessage.success("需求添加成功");
        dialogVisible.value = false;
        form.value = {
          name: "",
          area: "",
          institution: "",
          transform: "",
          stage: "",
          desc: "",
        };
        await getList1();
      }
    }
  } catch (error) {
    ElMessage.error("提交失败: " + error.message);
  }
};

// 监听标签切换
watch(activeTab, async (newVal) => {
  if (newVal === "techResults") {
    await getList();
  } else {
    await getList1();
  }
});

// 初始化加载
onMounted(async () => {
  await getList();
});
</script>

<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.main-wrapper {
  width: 90%; /* 使用百分比宽度，自适应屏幕 */
  max-width: 1200px; /* 最大宽度限制 */
  margin: 20px auto;
  padding-bottom: 60px; /* 根据导航栏高度调整 */
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.main-container {
  width: 100%;
  box-sizing: border-box;
}

.tabs-section {
  display: flex;
  justify-content: center;
}

.el-tabs >>> .el-tabs__item {
  margin: 0 20px;
  font-size: clamp(1rem, 3vw, 1.2rem); /* 动态字体大小 */
}

.search-section {
  margin: 20px 0;
}

.summary-box {
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.summary-image {
  width: clamp(30px, 6vw, 50px); /* 动态图片大小 */
  height: clamp(30px, 6vw, 50px); /* 动态图片大小 */
  margin-right: 10px;
}

.summary-text {
  font-size: clamp(1.1rem, 3vw, 1.3rem); /* 动态字体大小 */
  font-weight: bold;
}

.summary-count {
  font-size: clamp(1.1rem, 3vw, 1.3rem); /* 动态字体大小 */
  color: blue;
  margin-left: 10px;
  font-weight: 600;
}

.list-container {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.list-item {
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: clamp(15px, 3vw, 20px); /* 动态内边距 */
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.name {
  font-size: clamp(1rem, 3vw, 1.1rem); /* 动态字体大小 */
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: clamp(0.9rem, 2.5vw, 1rem); /* 动态字体大小 */
}

.item-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.view-btn {
  margin-top: auto;
  align-self: center;
  margin-top: 10px;
  font-size: clamp(0.9rem, 2.5vw, 1rem); /* 动态字体大小 */
}

.button-section {
  margin: 20px 0;
  text-align: right;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .tabs-section {
    margin-top: 1rem;
  }

  .button-section {
    text-align: center;
    margin: 1rem 0;
  }

  .summary-image {
    width: 40px;
    height: 40px;
  }

  .list-item {
    padding: 0.8rem;
  }

  .el-tabs >>> .el-tabs__item {
    margin: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  .el-dialog {
    width: 95% !important;
  }

  .el-form-item {
    margin-bottom: 0.8rem;
  }

  .el-form-item__label {
    font-size: 0.9rem;
  }
}

/* 通用优化 */
[class^="el-"] {
  word-break: break-word;
}
</style>
