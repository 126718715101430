import http from './axios';
export function getNotiList(data) {
    return http.post('/frontipad/notice/notice_type_list', data);
}
export function getListNotice(data) {
    return http.post('/frontipad/notice/notice_list', data);
}
// 列表点击详情
export function getListDetail(data) {
    return http.post('/frontipad/notice/notice_info', data);
}