<template>
  <div style="padding-bottom: 60px">
    <!-- 头部通栏 -->
    <div class="header">
      <div class="header-content">首页</div>
    </div>
    <!-- 按钮部分 -->

    <!-- <div class="demand-release">
      <span class="login-register" @click="jumpLogin">注册/登录</span>
    </div> -->

    <!-- 搜索框和选项卡 -->
    <div class="content-section">
      <!-- 修改搜索区域结构 -->
      <div class="search-filter-container">
        <el-input
          class="search-input"
          placeholder="搜索热点通知、最新政策等..."
          v-model="searchInput"
          @keyup.enter="handleSearch"
        ></el-input>
        <el-cascader
          v-model="selectedArea"
          :options="areaOptions"
          :props="areaProps"
          placeholder="请选择省市区"
          clearable
          class="area-filter"
          @change="handleAreaChange"
        ></el-cascader>
      </div>

      <!-- <p>你选择的地区是：{{ selectedArea.join(" - ") }}</p> -->
      <!-- 选项卡 -->
      <div class="tabs-container">
        <el-tabs v-model="currentTab" @tab-click="handleTabClick">
          <el-tab-pane label="全部" name=""></el-tab-pane>
          <el-tab-pane
            v-for="item in tabs"
            :key="item.id"
            :label="item.notice_type_name"
            :name="item.id.toString()"
          ></el-tab-pane>
        </el-tabs>
        <div class="notice-list">
          <div
            v-for="(item, index) in noticeList"
            :key="index"
            class="notice-item"
            @click="jumpInfo(item.id)"
          >
            <div class="notice-title">
              <!-- 根据 notice_type_id 显示不同的文本 -->
              <span v-if="item.notice_type_id == 13" class="zhengceKu"
                >政策库</span
              >
              <span v-if="item.notice_type_id == 5" class="tuisong"
                >政策推送</span
              >
              <span v-if="item.notice_type_id == 4" class="dongjian"
                >最新洞见</span
              >
              <span v-if="item.notice_type_id == 3" class="mingdan"
                >名单公示</span
              >
              <span v-if="item.notice_type_id == 1" class="zhishi"
                >知识产权</span
              >
              {{ item.title || "--" }}
            </div>
            <div class="notice-info">
              <div class="notice-source">{{ item.source || "--" }}</div>
              <div class="notice-date">
                发布日期: {{ item.publish_date || item.create_time || "--" }}
                <span v-if="item.death_date">
                  截止日期: {{ item.death_date || "--" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页组件 -->
        <el-pagination
          class="pagination-wrapper"
          background
          layout="prev, pager, next"
          :total="total"
          v-model:current-page="currentPage"
          @update:current-page="handlePageChange"
        ></el-pagination>
      </div>
      <EndIcons />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { defineOptions, ref, onMounted, watch } from "vue";
import { getNotiList, getListNotice, getLis } from "../api/index.js";
import EndIcons from "../components/EndIcons.vue";
import areaData from "../assets/areaData.js";
defineOptions({
  name: "firstPage",
});
const router = useRouter();
const notice_type_id = ref("");
const searchInput = ref("");
const tabs = ref([]);
const currentTab = ref("");
const noticeList = ref([]);
const currentPage = ref(1);
const total = ref(0);
const province = ref("");
const city = ref("");
const area = ref("");
//tabs栏切换
const handleTabClick = (tab) => {
  console.log(tab.paneName);
  notice_type_id.value = tab.paneName;
  getList();
};
// 跳转登录
const jumpLogin = () => {
  router.push({ name: "LoginView" });
};
const jumpInfo = (id) => {
  console.log(id);
  router.push({ name: "listDetail", query: { id } });
};
// 获取标题接口
async function getListTitle() {
  const res = await getNotiList();
  tabs.value = res.data;
}
// 获取整个列表
async function getList() {
  const res = await getListNotice({
    keyword: searchInput.value,
    project_name: "",
    province: province.value || "",
    city: city.value || "",
    area: area.value || "",
    notice_type_id: notice_type_id.value,
    page: currentPage.value,
    limit: 10,
  });
  noticeList.value = res.data.lists;
  total.value = res.data.total;
}
// 分页
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
  getList();
};
// 监听 searchInput 和 notice_type_id 的变化，重置页码
watch([searchInput, notice_type_id], () => {
  currentPage.value = 1;
  getList();
});
// 搜索
function handleSearch() {
  getList();
}
// 省市区三级

// 定义选中的地区
const selectedArea = ref([]);
// 定义地区选项
const areaOptions = ref(areaData);
// 定义 Cascader 组件的 props
const areaProps = ref({
  value: "code",
  label: "name",
  children: "children",
});
// 递归查找名称的函数
const findNameByCode = (data, code) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.code === code) {
      return item.name;
    }
    if (item.children) {
      const result = findNameByCode(item.children, code);
      if (result) {
        return result;
      }
    }
  }
  return null;
};
// 修改后的处理函数
const handleAreaChange = (codes) => {
  // 处理空值情况
  if (!codes || codes.length === 0) {
    console.log("已清除选择");
    province.value = "";
    city.value = "";
    area.value = "";
    getList();

    return;
  }

  // 安全获取各层级代码
  const [provinceCode, cityCode, districtCode] = codes;

  // 优化后的查找逻辑
  const getAreaName = (code) =>
    code ? findNameByCode(areaData, code) : "全部";
  province.value = getAreaName(provinceCode);
  city.value = getAreaName(cityCode);
  area.value = getAreaName(districtCode);
  console.log("province.value", province.value);
  console.log("city.value", city.value);
  console.log("area.value", area.value);
  getList();
};
onMounted(() => {
  getListTitle();
  getList();
});
</script>
<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.demand-release {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.login-register {
  margin-right: 40px;
  font-size: 14px;
  color: blue;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding: 0 1rem; /* 增加两侧留白 */
  width: 100%;
  box-sizing: border-box;
}
/* 搜索区域响应式布局 */
.search-filter-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap; /* 小屏幕时换行 */
}

.el-input, .el-cascader {
  flex: 1 1 300px; /* 基础宽度300px，自动伸缩 */
  min-width: 280px; /* 最小宽度 */
  margin-bottom: 1rem;
}

/* .el-tabs {
  width: 800px;
} */
/* 选项卡容器 */
.tabs-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-sizing: border-box;
}

/* 选项卡列表 */
.notice-list {
  margin-top: 20px;
}

.zhengceKu {
  font-size: 11px;
  background: #ffdcab;
  color: #f59000;
  border-radius: 5px;
  display: inline-block;
}

.tuisong {
  font-size: 11px;
  background: #e4eeff;
  color: #5091ff;
  border-radius: 5px;
  display: inline-block;
}

.dongjian {
  font-size: 11px;
  background: #f2daff;
  color: #b931ff;
  border-radius: 5px;
  display: inline-block;
}

.mingdan {
  font-size: 11px;
  background: #ffd5dc;
  color: #ff4c6a;
  border-radius: 5px;
  display: inline-block;
}

.zhishi {
  font-size: 11px;
  background: yellowgreen;
  color: greenyellow;
  border-radius: 5px;
  display: inline-block;
}
/* 每个通知项 */
.notice-item {
  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

/* 标题样式 */
.notice-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* 信息部分 */
.notice-info {
  font-size: 14px;
  color: #666;
}

/* 来源 */
.notice-source {
  margin-bottom: 5px;
}

/* 日期 */
.notice-date {
  color: #999;
}
.pagination-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
