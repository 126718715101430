<template>
  <div>
    <div class="header">
      <div class="header-content">项目测评</div>
    </div>
    <div class="main-container">
      <div class="search-section">
        <el-input
          v-model="searchText"
          placeholder="搜索相关评测试卷"
          
          @keyup.enter="handleSearch"
        ></el-input>
      </div>
      <div class="button-group">
        <button
          v-for="item in types"
          :key="item.id"
          @click="selectType(item)"
          :class="{ active: currentType === item.id }"
        >
          {{ item.evaluation_type_name }}
        </button>
      </div>
      <div class="filter-list">
        <div v-for="item in filteredList" :key="item.id" class="filter-item">
          <div class="item-container">
            <img
              :src="imgsUrl + item.evaluation_image"
              alt="Evaluation Image"
              class="item-image"
            />
            <div class="item-content">
              <div class="item-name">{{ item.evaluation_name }}</div>
              <div class="item-type">
                <span
                  v-for="(typeName, index) in item.evaluation_type_name.split(
                    ','
                  )"
                  :key="index"
                >
                  #{{ typeName }}
                </span>
              </div>
              <button class="item-button" @click="jumpInfo(item.id)">
                立即测评
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页组件 -->
      <el-pagination
      class='pagination-wrapper'
        background
        layout="prev, pager, next"
        :total="total"
        v-model:current-page="currentPage"
        @update:current-page="handlePageChange"
        
      ></el-pagination>
    </div>
    <EndIcons />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { getEvaType, getEvaList } from "../api/evaluation.js";
import { useRouter } from "vue-router";
import EndIcons from "../components/EndIcons.vue";

const router = useRouter();

const searchText = ref("");
const currentType = ref(""); // 默认不选中
const types = ref([]);
const originalList = ref([]);
const currentPage = ref(1);
const total = ref(0);
const imgsUrl = "https://admin.syshunying.cn/";

const filteredList = computed(() => {
  if (!Array.isArray(originalList.value)) {
    return [];
  }
  if (!currentType.value) {
    return originalList.value;
  } else {
    return originalList.value.filter((item) =>
      item.evaluation_type_id.includes(currentType.value)
    );
  }
});

const selectType = (type) => {
  if (currentType.value === type.id) {
    currentType.value = "";
  } else {
    currentType.value = type.id;
  }
  getList();
};

const getType = async () => {
  const res = await getEvaType({ pid: 1 });
  if (res && res.data) {
    types.value = res.data;
  }
};

const getList = async () => {
  const params = {
    page: currentPage.value,
    limit: 9,
    keyword: searchText.value,
    evaluation_type_pid: 1,
    evaluation_type_id: currentType.value,
  };
  const res = await getEvaList(params);
  if (res && res.data) {
    originalList.value = res.data.lists || [];
    total.value = res.data.total || 0;
  }
};
// 分页
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
  getList();
};
// 搜索
function handleSearch() {
  getList();
}
// 跳转详情
function jumpInfo(id) {
  router.push({ name: "evaluationInfo", query: { id } });
}
onMounted(() => {
  getType();
  getList();
});
</script>
<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.main-container {
  height:1200px;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.search-section {
  width: 100%;
  margin: 1.5rem auto;
  display:flex;
  justify-content:center
}

.el-input {
  width: 100% !important;
  max-width: 800px;
  height: auto !important;
  padding: 0.8rem;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.button-group button {
  width: 5rem;
  height: 2.5rem;
  border-radius: 38%;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  transition: all 0.3s ease;
  border: 2px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-group button.active {
  background-color: #4caf50;
  color: white;
  border-color: #4caf50;
}

.filter-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.2rem;
  justify-content: center;
  height:800px
}

.filter-item {
  width: 100%;
  margin: 0;
}

  
.item-container {
  width: 100%;
  
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 5px;
  transition: transform 0.3s ease;
  margin: 1.5rem auto;

}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.item-content {
  width: 100%;
  margin-left: 0;
  text-align: center;
}

.item-name {
  font-size: 16px;
  font-weight: bold;
}

.item-type {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.item-type span {
  margin-right: 5px;
}

.item-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.item-button:hover {
  background-color: #3b8ff0;
}
/* 分页适配 */
.pagination-wrapper {
  margin-top:4rem;
  padding-bottom:4rem;
 
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto; /* 防止分页溢出 */
}

/* 移动端适配 */


@media (max-width: 480px) {
  .header {
    height: 3.5rem;
  }
  
  .item-image {
    width: 80px;
    height: 80px;
  }
  
  .item-button {
    padding: 0.6rem;
  }
}
</style>
