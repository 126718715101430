
import axios from "axios";
import router from "@/router";
import { ElMessage } from 'element-plus';
const http = axios.create({
  baseURL: "https://www.syshunying.cn",
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});


// 请求拦截器
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config.headers.token = token; // 根据实际需求使用 Authorization 等标准字段
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    // 可以在这里统一处理业务状态码
    if (response.data.code == 600) {
      const redirectPath = encodeURIComponent(router.currentRoute.value.fullPath);
      ElMessage.warning({message:'请先登录'});
      router.replace({
        name: "LoginView",
        query: {
          redirect: redirectPath // 编码后参数
        }
      }); 
      
      //confirm('请先登录')
    }
    return response.data;
  },
  (error) => {
    // 统一错误处理
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.error("身份验证失败");
          // 跳转到登录页
          break;
        case 403:
          console.error("权限不足");
          break;
        case 500:
          console.error("服务器错误");
          break;
        default:
          console.error(`请求错误: ${error.message}`);
      }
    } else {
      console.error("网络连接异常");
    }
    return Promise.reject(error);
  }
);

export default http;
