<template>
  <div class="container">
    <div class="header">
      <div class="header-content">AI对话</div>
    </div>

    <!-- 聊天消息区域 -->
    <div class="chat-container">
      <div class="messages" ref="messagesContainer">
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="['message', message.role]"
        >
          <div class="bubble">
            <template v-if="message.role === 'assistant'">
              <div class="thinking-chain" v-if="message.thinking">
                {{ message.thinking }}
              </div>
              <div class="content">{{ message.content }}</div>
            </template>
            <div v-else class="content">{{ message.content }}</div>
          </div>
        </div>
        <div v-if="isLoading" class="loading">AI正在思考中...</div>
      </div>

      <!-- 输入区域 -->
      <div class="input-area">
        <textarea
          v-model="inputMessage"
          placeholder="请输入您的问题"
          @keydown.enter.exact.prevent="sendMessage"
        ></textarea>
        <button @click="sendMessage" :disabled="isLoading">
          {{ isLoading ? "发送中..." : "发送" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,  nextTick,onMounted } from "vue";
import cloudbase from "@cloudbase/js-sdk";
import { getAiwords } from "../api/aiPage.js";

const app = cloudbase.init({
  env: "shunqifuai-8gs9k2mz486feefa",
});
const auth = app.auth();

// 响应式数据
const messages = ref([]);
const inputMessage = ref("");
const isLoading = ref(false);
const messagesContainer = ref(null);

// 敏感词汇列表
const sensitiveWords = []; // 替换为实际的敏感词汇
onMounted(async () => {
  try {
    const res = await getAiwords();
    sensitiveWords.value = res.data;
  } catch (error) {
    console.error("获取详情失败:", error);
  }
});
// 检查输入是否包含敏感词汇
const containsSensitiveWord = (input) => {
  return sensitiveWords.value.some((word) => input.includes(word));
};

// 自动滚动到底部
const scrollToBottom = async () => {
  await nextTick();
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
};

const sendMessage = async () => {
  if (!inputMessage.value.trim() || isLoading.value) return;

  // 检查输入是否包含敏感词汇
  if (containsSensitiveWord(inputMessage.value.trim())) {
    messages.value.push({
      role: "assistant",
      content: "您的输入包含敏感词汇，请重新输入。",
      thinking: null,
    });
    inputMessage.value = "";
    await scrollToBottom();
    return;
  }

  try {
    // 添加用户消息
    messages.value.push({
      role: "user",
      content: inputMessage.value.trim(),
      thinking: null,
    });

    const userMessage = inputMessage.value.trim();
    inputMessage.value = "";
    isLoading.value = true;
    await scrollToBottom();

    // 添加初始AI消息
    messages.value.push({
      role: "assistant",
      content: "",
      thinking: "",
      isStreaming: true,
    });

    // 调用AI接口
    await auth.signInAnonymously();
    const ai = app.ai();
    const aiModel = ai.createModel("deepseek");
    const res = await aiModel.streamText({
      model: "deepseek-r1",
      messages: [{ role: "user", content: userMessage }],
    });

    // 处理流式响应
    const aiMessageIndex = messages.value.length - 1;
    for await (const data of res.dataStream) {
      const think = data?.choices?.[0]?.delta?.reasoning_content || "";
      const text = data?.choices?.[0]?.delta?.content || "";

      messages.value[aiMessageIndex] = {
        ...messages.value[aiMessageIndex],
        thinking: messages.value[aiMessageIndex].thinking + think,
        content: messages.value[aiMessageIndex].content + text,
      };
      await scrollToBottom();
    }
  } catch (error) {
    console.error("请求出错:", error);
    messages.value.push({
      role: "assistant",
      content: "抱歉，请求出现错误，请稍后再试",
      thinking: null,
    });
  } finally {
    isLoading.value = false;
    await scrollToBottom();
  }
};
</script>

<style scoped>
/* 新增字体定义 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
}
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}
.messages {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  background: #f8fafc;
  border-radius: 16px;
  margin-bottom: 24px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}
.message {
  margin: 16px 0;
  display: flex;
  transition: transform 0.2s ease;
}
.message:hover {
  transform: translateX(4px);
}
.bubble {
  max-width: 75%;
  padding: 16px 20px;
  border-radius: 16px;
  line-height: 1.6;
  font-size: 15px;
  transition: all 0.2s ease;
}
.user .bubble {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  color: white;
  border-radius: 20px 20px 4px 20px;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}
.assistant .bubble {
  background: white;
  color: #1e293b;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px 20px 20px 20px;
}
.thinking-chain {
  color: #64748b;
  font-size: 13px;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f1f5f9;
  line-height: 1.5;
  font-style: italic;
}
.input-area {
  display: flex;
  gap: 16px;
  padding: 16px 0;
  border-top: 1px solid #e2e8f0;
}
textarea {
  flex: 1;
  padding: 14px 20px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  resize: none;
  min-height: 52px;
  max-height: 150px;
  font-size: 15px;
  transition: all 0.2s ease;
  background: white;
  color: #1e293b;
}
textarea:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}
textarea::placeholder {
  color: #94a3b8;
}
button {
  padding: 0 28px;
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  letter-spacing: 0.3px;
  height: 82px;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}
button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(37, 99, 235, 0.25);
}
button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);
}
button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: #94a3b8;
}
.loading {
  text-align: center;
  color: #64748b;
  padding: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.loading::after {
  content: "";
  width: 6px;
  height: 6px;
  background: #2563eb;
  border-radius: 50%;
  animation: loading 1s infinite;
}
@keyframes loading {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
</style>
