<template>
  <div>
    <div class="header">
      <div class="header-content">列表详情</div>
    </div>
    <div class="main-container">
      <div class="detail-box" v-if="detail">
        <h2>{{ detail.title }}</h2>
        <p>{{ detail.source }} | {{ detail.create_time }}</p>
        <div v-if="detail.content" v-html="detail.content" class="content"></div>
        <div v-if="detail.file && detail.file.url">
          <a :href="detail.file.url" download>下载附件</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { getListDetail } from "../api/index.js";

const route = useRoute();

const detail = ref(null);
const id = ref(route.query.id);

onMounted(async () => {
  try {
    const res = await getListDetail({ id: id.value });
    detail.value = res.data;
  } catch (error) {
    console.error('获取详情失败:', error);
  }
});
</script>

<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.main-container {
  width: 90%; /* 使用百分比宽度，自适应屏幕 */
  max-width: 1100px; /* 设置最大宽度，避免在大屏幕上过于分散 */
  margin: 20px auto;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.detail-box {
  background-color: #f0f2f5;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.detail-box h1 {
  margin-top: 0;
}

.detail-box p {
  color: #666;
  margin-bottom: 10px;
}

.content {
  margin-bottom: 20px;
}

.detail-box a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

/* 媒体查询，针对小屏幕进行优化 */
@media (max-width: 768px) {
  .main-container {
    width: 95%; /* 在小屏幕上增加宽度占比 */
    padding: 10px; /* 减少内边距 */
  }

  .detail-box {
    padding: 15px; /* 减少内边距 */
  }

  .detail-box h2 {
    font-size: 20px; /* 减小标题字体大小 */
  }

  .detail-box p {
    font-size: 14px; /* 减小信息字体大小 */
  }

  .content {
    font-size: 14px; /* 减小内容字体大小 */
  }
}
</style>