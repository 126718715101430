<template>
  <div>
    <div class="header">
      <div class="header-content">
        <div>需求详情</div>
      </div>
    </div>
    <div class="main-container">
      <div class="detail-box" v-if="detail">
        <div class="info-box">
          <h3>{{ detail.name }}</h3>
          <div class="field-box">
            <div class="field-item">
              <img src="../assets/ach1.png" class="item-icon" />
              <span>技术领域:</span>
              <span class="item-name">{{ detail.area ? detail.area:'暂无' }}</span>
            </div>
            <div class="field-item">
              <img src="../assets/ach2.png" class="item-icon" />
              <span>所属机构:</span>
              <span class="item-name">{{ detail.institution ? detail.institution:'暂无'   }}</span>
            </div>
            <div class="field-item">
              <img src="../assets/ach3.png" class="item-icon" />
              <span>转化方式:</span>
              <span class="item-name"> {{ detail.transform ? detail.transform:'暂无' }}</span>
            </div>
            <div class="field-item">
              <img src="../assets/ach4.png" class="item-icon" />
              <span>所处阶段:</span>
              <span class="item-name">{{ detail.stage ? detail.stage:'暂无' }}</span>
            </div>
          </div>
        </div>

        <div class="desc-section">
          <div class="desc-title">需求描述</div>
          <div class="desc-content" v-if="detail.desc">{{ detail.desc }}</div>
          <div class="desc-content" v-else>暂无</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { getRequirDetail } from "../api/achieve.js";

const route = useRoute();

const detail = ref(null);
const id = ref(route.query.id);

onMounted(async () => {
  try {
    const res = await getRequirDetail({ techid: id.value });
    detail.value = res.data;
  } catch (error) {
    console.error("获取详情失败:", error);
  }
});
</script>

<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
/* 整体容器样式，用于适配不同屏幕 */
.main-container {
  width: 90%; /* 使用百分比宽度，在小屏幕上有一定边距 */
  max-width: 1200px; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
  padding: 20px;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.detail-box {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%; /* 宽度自适应父容器 */
  margin: 20px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.info-box {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* 添加圆角 */
  background-color: #f8f9fa; /* 设置背景颜色 */
  padding: 20px; /* 添加内边距 */
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.field-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.field-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
}

.item-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}

.item-name {
  font-size: 16px;
  color: #333;
  margin-left: 10px;
}

.item-name span {
  font-size: 14px;
}

.desc-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.desc-title {
  position: relative;
  font-size: 18px;
  color: #1a6ae3;
  font-weight: 600;
  margin-bottom: 15px;
  padding-left: 12px;
}

.desc-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 16px;
  background-color: #1a6ae3;
  border-radius: 2px;
}

.desc-content {
  font-size: 14px;
  color: #666;
  line-height: 1.8;
  text-align: justify;
  white-space: pre-line;
  padding: 15px;
  background: white;
  border-radius: 6px;
  border: 1px solid #eee;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

/* 媒体查询，针对小屏幕进行优化 */
@media (max-width: 768px) {
  .main-container {
    width: 95%; /* 在小屏幕上增加宽度占比 */
    padding: 10px; /* 减少内边距 */
  }

  .detail-box,
  .info-box,
  .field-box,
  .desc-section {
    padding: 15px; /* 减少内边距 */
  }

  .item-name {
    font-size: 14px; /* 减小字体大小 */
  }

  .desc-title {
    font-size: 16px; /* 减小标题字体大小 */
  }

  .desc-content {
    font-size: 13px; /* 减小描述内容字体大小 */
  }
}
</style>