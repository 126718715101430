<template>
  <div class="icon-section">
    <div class="icon-item" @click="gotoHome">
      <img src="../assets/firstPage.png" alt="首页" class="el-icon" />
      <span>首页</span>
    </div>
    <div class="icon-item" @click="gotoAchieve">
      <img src="../assets/achievement.png" alt="技术成果库" class="el-icon" />
      <span>技术成果库</span>
    </div>
    <div class="icon-item" @click="gotoProjectEvaluation">
      <img src="../assets/eva.png" alt="项目测评" class="el-icon" />
      <span>项目测评</span>
    </div>
    <div class="icon-item" @click="gotoMine">
      <img src="../assets/my.png" alt="个人中心" class="el-icon" />
      <span>个人中心</span>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function gotoHome() {
  router.push({ name: "firstPage" });
}
function gotoAchieve() {
  router.push({ name: "achievePage" });
}
function gotoMine() {
  router.push({ name: "myPage" });
}
// 项目测评
const gotoProjectEvaluation = () => {
  router.push({ name: "evaluationPage" });
};
</script>
<style scoped>


.icon-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-around;
  z-index: 1000;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-item span {
  font-size: 14px;
  margin-top: 5px;
}

.icon-item .el-icon {
  width: 24px;
  height: 24px;
}
</style>
