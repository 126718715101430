<template>
  <div>
    <div class="header">
      <div class="header-content">测评详情</div>
    </div>
    <div class="main-container">
      <div class="evaluation-header">
        <img :src="urls" alt="Evaluation Image" class="item-image" />
        <h2 class="evaluation-name">{{ evaluationData.evaluation_name }}</h2>
        <div class="evaluation-type">{{ evaluationData.evaluation_type_name }}</div>
        
      </div>
      <div class="info-line">
        {{ length }}道精选题目 + 专业测评报告
      </div>
      <div class="big-box">
        <div class="section">
          <h3>测评简介:</h3>
          <p>{{ evaluationData.evaluation_intro }}</p>
        </div>
        <div class="section">
          <h3>测评说明:</h3>
          <p>{{ evaluationData.evaluation_explain }}</p>
        </div>
        <button class="immediate-assessment" @click="jumpTest(evaluationData.id)">立即测评</button>
      </div>
     
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

import { getEvaDetail } from "../api/evaluation.js";
const router = useRouter();

const route = useRoute();
const id = ref(route.query.id);
const imgBaseUrl = 'https://admin.syshunying.cn/';
const urls=ref('')
const evaluationData = ref({});
const length=ref(0)
async function getDetail() {
  if (!id.value) return; // 提前终止无效调用
  try {
    const res = await getEvaDetail({ id: id.value });
    // 增加响应有效性验证
    if (!res?.data) {
      throw new Error('Invalid response structure');
    }
    // 处理数据
    evaluationData.value = res.data;
    length.value = res.data.question?.length || 0; // 处理可能的 undefined
    urls.value = imgBaseUrl + (res.data.evaluation_image || ''); // 处理空值情况

  } catch (error) {
    console.error('Failed to fetch evaluation details:', error);

  }
}
function jumpTest(id) {
  router.push({ name: "evaTest",query:{id} });
}
onMounted(() => {
  getDetail();
});
</script>
<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.main-container {
  
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  padding: 0 10px;
  box-sizing: border-box;
}

.evaluation-header {
  margin-top: clamp(20px, 3vw, 30px);
  text-align: center;
}

.evaluation-name {
  font-size: clamp(22px, 3.5vw, 28px);
  color: #333;
  margin: 15px 0;
  line-height: 1.3;
}

.evaluation-type {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: clamp(12px, 2vw, 14px);
  color: #666;
  display: inline-block;
  margin-bottom: clamp(15px, 3vw, 20px);
}

.info-line {
  font-size: clamp(14px, 2vw, 16px);
  color: #666;
  margin-bottom: clamp(15px, 3vw, 20px);
  text-align: center;
}

.big-box {
  background-color: #f9f9f9;
  padding: clamp(15px, 3vw, 20px);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin: 0 auto;
}

.section h3 {
  font-size: clamp(16px, 2.5vw, 18px);
  color: #333;
  margin-bottom: 8px;
}

.section p {
  font-size: clamp(14px, 2vw, 16px);
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
}

.immediate-assessment {
  background-color: #409EFF;
  color: white;
  padding: clamp(10px, 2vw, 15px) clamp(20px, 4vw, 30px);
  border: none;
  border-radius: 5px;
  font-size: clamp(14px, 2vw, 16px);
  cursor: pointer;
  margin: clamp(15px, 3vw, 20px) auto 0;
  display: block;
  transition: background-color 0.3s;
}

.item-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  aspect-ratio: 800/280;
  object-fit: cover;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 768px) {
  .main-container {
    width: 94%;
    padding: 0 5px;
  }
  
  .section p {
    line-height: 1.5;
  }
}

@media screen and (max-width: 480px) {
  .evaluation-name {
    line-height: 1.2;
    margin: 10px 0;
  }
  
  .immediate-assessment {
    width: 100%;
    max-width: 280px;
  }
}
</style>
