<template>
  <!-- 模板部分保持不变 -->
  <div class="background">
    <div class="container">
      <img src="../assets/uniappLogin.jpg" alt="登录图片" class="login-image" />
      <div class="form-container">
        <el-form :model="form" :rules="rules" ref="formRef" class="form-group">
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-button type="primary" @click="enterLogin" class="login-button"
            >登录</el-button
          >
        </el-form>
      </div>
      <div class="hint">请先扫码注册小程序后输入手机号进行登录</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getLogin } from "@/api/login.js";

const router = useRouter();

const form = ref({
  mobile: "",
});

const rules = {
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
  ],
};

const formRef = ref(null);

const enterLogin = () => {
  formRef.value.validate(async (valid) => {
    if (!valid) {
      alert("请检查输入的手机号是否正确");
      return false;
    }

    try {
      const res = await getLogin({
        mobile: form.value.mobile,
      });

      // 新增状态码判断
      if (res.code === 200) {
        localStorage.setItem("token", res.data.token);
        // 获取重定向地址
        const redirect = router.currentRoute.value.query.redirect;

        if (redirect) {
          // 解码并跳转
          router.push(decodeURIComponent(redirect));
        } else {
          // 默认跳转地址
          router.push("/firstPage");
        }
      } else {
        // 显示服务端返回的错误信息（根据实际字段调整）
        alert(res.message || "登录失败，请稍后重试");
      }
    } catch (error) {
      console.error("登录失败:", error);
      // 显示更具体的错误信息
      const errorMsg = error.response?.data?.message || "请求异常，请检查网络";
      alert(`登录失败：${errorMsg}`);
    }
  });
};
</script>

<style scoped>
.background {
  background-image: url("../images/loginBg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.login-image {
  width: 50%;
  height: auto;
  margin-bottom: 30px;
}

.form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.el-form-item {
  margin-bottom: 0;
  flex: 1;
}

.el-input {
  width: 98%;
}

.login-button {
  /* margin-left: 10px; */
  padding: 12px 20px;
  font-size: 12px;
}

.hint {
  font-size: 14px;
  color: #ff5733;
  text-align: center;
  margin-top: 25px;
}
</style>
