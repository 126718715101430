import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import firstPage from "../views/firstPage.vue";
import achievePage from "../views/achievePage.vue";
import myPage from "../views/myPage.vue";
import evaluationPage from "../views/evaluationPage.vue";
import evaluationInfo from "../views/evaluationInfo.vue";
import registerPage from "../views/registerPage.vue";
import companyButler from "../views/companyButler.vue";
import companyDetail from "../views/companyDetail.vue";
import evaTest from "../views/evaTest.vue";
import listDetail from "../views/listDetail.vue";
import aboutUs from "../views/aboutUs.vue";
import requireDetail from "../views/requireDetail.vue";
import achieveDetail from "../views/achieveDetail.vue";
import aiPage from "../views/aiPage.vue";
const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    
  },
  // router.js
  {
    path: "/LoginView",
    name: "LoginView",
    component: () => import("@/views/LoginView.vue"),

    props: (route) => ({ query: route.query }),
  },
  {
    path: "/firstPage",
    name: "firstPage",
    component: firstPage,
  },
  {
    path: "/achievePage",
    name: "achievePage",
    component: achievePage,
  },
  {
    path: "/myPage",
    name: "myPage",
    component: myPage,
  },
  {
    path: "/evaluationPage",
    name: "evaluationPage",
    component: evaluationPage,
  },
  {
    path: "/evaluationInfo",
    name: "evaluationInfo",
    component: evaluationInfo,
  },
  {
    path: "/registerPage",
    name: "registerPage",
    component: registerPage,
  },
  {
    path: "/companyButler",
    name: "companyButler",
    component: companyButler,
  },
  {
    path: "/companyDetail",
    name: "companyDetail",
    component: companyDetail,
  },
  {
    path: "/evaTest",
    name: "evaTest",
    component: evaTest,
  },
  {
    path: "/listDetail",
    name: "listDetail",
    component: listDetail,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
  },
  {
    path: "/requireDetail",
    name: "requireDetail",
    component: requireDetail,
  },
  {
    path: "/achieveDetail",
    name: "achieveDetail",
    component: achieveDetail,
  },
  {
    path: "/aiPage",
    name: "aiPage",
    component: aiPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
