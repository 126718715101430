import http from './axios';
export function getAchieveList(data) {
    return http.post('/frontipad/technology/list', data);
}
// 新增成果
export function getAchieveAdd(data) {
    return http.post('/frontipad/technology/add', data);
}
// 需求列表
export function getRequirList(data) {
    return http.post('/frontipad/requirement/list', data);
}
// 需求新增
export function getRequirAdd(data) {
    return http.post('/frontipad/requirement/add', data);
}
// 成果详情
export function getAchieveDetail(data) {
    return http.post('/frontipad/requirement/detail', data);
}
// 需求详情
export function getRequirDetail(data) {
    return http.post('/frontipad/requirement/detail', data);
}