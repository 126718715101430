<template>
  <div>
    <div class="header">
      <div class="header-content">企业详情</div>
    </div>
    <div class="container">
      <div class="content">
        <div class="box box1">
          <div class="title-section">
            <img
              src="https://www.syshunying.cn/xiaochengxu/newversion/img/1024/left.png"
              style="width: 20px; height: auto"
            />
            <h3>河南泛海星信息科技有限公司</h3>
            <img
              src="https://www.syshunying.cn/xiaochengxu/newversion/img/1024/right.png"
              style="width: 20px; height: auto"
            />
          </div>
          <div class="columns">
            <p>研发费用：是</p>
            <p>参保人数：1</p>
            <p>法定代表人：朱婉卿</p>
            <p>人员数量：少于50人</p>
            <p>入驻时间：2021-12-27</p>
            <p>成立时间：2021-12-27</p>
            <p>电话：18738192590</p>
            <p>注册资本：100万元人民币</p>
            <p>邮箱：564257751@qq.com</p>
            <p>官网：暂无</p>
          </div>
          <p style="margin-left:80px">知识产权：商标信息：4，软著：6。</p>
          <p style="margin-left:80px">统一社会信用代码：91410108MA9KM6J5XA</p>
          <p style="margin-left:80px">地址：河南省郑州市惠济区江山路街道郑州财经学院大学科技园505</p>
          <p>
            企业基本信息：河南泛海星信息科技有限公司成立于2021-12-27，法定代表人为朱婉卿，注册资本为100万元人民币，统一社会信用代码为91410108MA9KM6J5XA，企业注册地址位于河南省郑州市惠济区江山路街道郑州财经学院大学科技园505，所属行业为科技推广和应用服务业，经营范围包含：一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；软件开发；计算机软硬件及辅助设备零售；计算机系统服务；信息系统集成服务；通讯设备销售；企业管理咨询；企业信用调查和评估；企业征信业务；市场调查（不含涉外调查）；企业信用管理咨询服务；互联网数据服务；大数据服务；人工智能应用软件开发；基于云平台的业务外包服务；物联网技术研发；云计算装备技术服务；心理咨询服务；教育咨询服务（不含涉许可审批的教育培训活动）；健康咨询服务（不含诊疗服务）；互联网销售（除销售需要许可的商品）；日用百货销售；文具用品零售；会议及展览服务；国内贸易代理；日用品销售；体育用品及器材零售；软件销售；组织文化艺术交流活动；信息技术咨询服务（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：建筑智能化系统设计；建设工程施工（除核电站建设经营、民用机场建设）（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）。河南泛海星信息科技有限公司目前的经营状态为存续（在营、开业、在册）。
          </p>
          <div class="right-align">
            <!-- <div class="status">
              <img src="../assets/time1.png" style="height: 20px" />
              进行中
            </div> -->
          </div>
        </div>
        <div class="box box2">
          <div class="title-section">
            <img
              src="https://www.syshunying.cn/xiaochengxu/newversion/img/1024/left.png"
              style="width: 20px; height: auto"
            />
            <h2>资质</h2>
            <img
              src="https://www.syshunying.cn/xiaochengxu/newversion/img/1024/right.png"
              style="width: 20px; height: auto"
            />
          </div>
          <div style="display: flex">
            <div style="color: #128bed; background: #e2f1fc">小微企业</div>
            <!-- <div style="color: #128bed; background: #e2f1fc; margin: 0 20px">
              高新技术企业
            </div> -->
            <div style="color: #128bed; background: #e2f1fc;margin: 0 20px">
              科技型中小企业
            </div>
          </div>
        </div>
        <div class="box box2">
          <div class="title-section">
            <img
              src="https://www.syshunying.cn/xiaochengxu/newversion/img/1024/left.png"
              style="width: 20px; height: auto"
            />
            <h2>需求详情</h2>
            <img
              src="https://www.syshunying.cn/xiaochengxu/newversion/img/1024/right.png"
              style="width: 20px; height: auto"
            />
          </div>
          <p>
            这是一段示例文本，可以根据需要随意修改。这是一段示例文本，可以根据需要随意修改。这是一段示例文本，可以根据需要随意修改。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.container {
  width: 90%; /* 使用百分比宽度，自适应屏幕 */
  max-width: 1200px; /* 设置最大宽度，避免在大屏幕上过于分散 */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.box {
  width: 100%; /* 让盒子宽度自适应容器 */
  max-width: 800px; /* 设置最大宽度 */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.box1 .columns {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 80px); /* 减去左侧外边距 */
  margin-left: 80px;
  box-sizing: border-box; /* 确保内边距包含在宽度内 */
}

.box1 .columns p {
  width: 50%;
  box-sizing: border-box;
}

.box1 {
  display: flex;
  flex-direction: column;
}

.box1 .title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.right-align {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;
}

.box2 .title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.box2 h2 {
  font-size: 20px;
  margin: 0 10px;
}

.box2 p {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
}
</style>