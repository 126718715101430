<template>
  <div class="background">
    <div class="main-container">
      <div class="box1">
        <h3>企业基础信息表（必填）</h3>
        <el-form label-width="140px">
          <el-form-item label="企业基本信息">
            <el-input
              v-model="company_info"
              placeholder="请输入企业基本信息"
            ></el-input>
          </el-form-item>
          <el-form-item label="入驻时间">
            <el-date-picker
              v-model="settled_time"
              type="date"
              placeholder="选择入驻时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="成立时间">
            <el-date-picker
              v-model="founding_time"
              type="date"
              placeholder="选择成立时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="参保人数">
            <el-input
              v-model="social_security"
              placeholder="请输入参保人数"
            ></el-input>
          </el-form-item>
          <el-form-item label="人员数量">
            <el-input v-model="people" placeholder="请输入人员数量"></el-input>
          </el-form-item>
          <el-form-item label="研发费用">
            <el-select v-model="research" placeholder="请选择是或否">
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
              <!-- 其他选项 -->
            </el-select>
          </el-form-item>

          <el-form-item label="知识产权">
            <el-input v-model="right"></el-input>
          </el-form-item>
          <el-form-item label="需求">
            <el-input
              v-model="requirement"
              type="textarea"
              placeholder="请输入需求详情"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="box2">
        <h3>企业其它信息表（选填）</h3>
        <el-form label-width="140px">
          <el-form-item label="企业名称">
            <el-input v-model="companyName"></el-input>
          </el-form-item>
          <el-form-item label="企业营收">
            <el-select v-model="companyRevenue" placeholder="请选择企业营收">
              <el-option label="50-100万元" value="50-100万元"></el-option>
              <el-option label="100-150万元" value="100-150万元"></el-option>
              <el-option label="150-200万元" value="150-200万元"></el-option>
              <el-option label="200-250万元" value="200-250万元"></el-option>
              <el-option label="250-300万元" value="250-300万元"></el-option>
              <el-option label="300万元以上" value="300万元以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="固定资产">
            <el-input v-model="fixedAssets"></el-input>
          </el-form-item>
        </el-form>
      </div> -->
      <el-button type="primary" class="submit-btn" @click="submitForm">提交信息</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { registerEva } from "../api/evaluation.js";
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';

const router = useRouter();
const company_info = ref("");
const settled_time = ref("");
const founding_time = ref("");
const social_security = ref("");
const people = ref("");
const research = ref("");
const right = ref("");
const requirement = ref("");

async function getRegister() {
  const res = await registerEva({
    company_info: company_info.value,
    settled_time: settled_time.value ? settled_time.value.toLocaleDateString("zh-CN") : "",
    founding_time: founding_time.value ? founding_time.value.toLocaleDateString("zh-CN") : "",
    social_security: social_security.value,
    people: people.value,
    research: research.value,
    right: right.value,
    requirement: requirement.value,
  });
  console.log("res", res);
  return res;
}

const submitForm = async () => {
  try {
    const result = await getRegister();
    if (result.success) {
      ElMessage.success('注册成功');
      // 跳转到测评页面
      router.push({ name: 'evaluationPage' });
    } else {
      ElMessage.error('注册失败，请检查输入信息');
    }
  } catch (error) {
    ElMessage.error('注册失败，请稍后再试');
  }
};
</script>

<style scoped>
.background {
  background-image: url("../images/loginBg.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* 修改为min-height，防止内容溢出时背景显示不全 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中 */
  width: 90%; /* 使用百分比宽度，让容器自适应屏幕 */
  max-width: 800px; /* 设置最大宽度，避免在大屏幕上过于宽大 */
}

.box1,
.box2 {
  width: 100%; /* 让盒子宽度跟随容器 */
  background-color: rgba(248, 248, 248, 0.8); /* 半透明背景 */
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
}

.box1 h3,
.box2 h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.box2 {
  margin-top: 20px; /* 调整盒子间距 */
}

.submit-btn {
  width: 200px;
  margin: auto; /* 水平居中 */
  margin-top: 20px;
}
</style>