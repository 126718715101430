<template>
  <div>
    <div class="header">
      <div class="header-content">个人中心</div>
    </div>
    <div class="bigbox">
      <div class="image-box">
        <img
          src="https://www.syshunying.cn/xiaochengxu/newversion/img/1103/bg1.png"
          alt="背景图"
        />
        <div class="profile">
          <div class="avatar">
            <img src="../assets/avatar.png" alt="背景图" />
          </div>
          <div class="info">
            <div class="name">姓名</div>
            <div class="icons">
              <span class="icon icon-certified" @click="jumpRegister"
                >企业认证</span
              >
              <span class="icon icon-manager" @click="jumpButler"
                >企业管家</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <ul class="list">
          <li @click="jumpRegister">
            <img src="../assets/ach2.png" alt="背景图" class="icon-image" />
            企业认证
          </li>
          <!-- <li>
          <img src="../assets/ach4.png" alt="背景图" class="icon-image" />
          测评记录
        </li> -->
          <li @click="jumpButler">
            <img src="../assets/login2.png" alt="背景图" class="icon-image" />
            企业管家
          </li>
          <li @click="jumpAboutus">
            <img src="../assets/achLogo.png" alt="背景图" class="icon-image" />
            关于我们
          </li>
        </ul>
      </div>
    </div>
  </div>
  <EndIcons />
</template>

<script setup>
import EndIcons from "../components/EndIcons.vue";
import { useRouter } from "vue-router";
const router = useRouter();

// 跳转测评注册
const jumpRegister = () => {
  router.push({ name: "registerPage" });
};
// 跳转测评注册
const jumpButler = () => {
  router.push({ name: "companyButler" });
};
// 跳转关于我们
const jumpAboutus = () => {
  router.push({ name: "aboutUs" });
};
</script>

<style scoped>
.header {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}
.header-content {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.bigbox{
  height:1000px;
  margin-top:60px
}

.image-box {
  position: relative;
  width: 100%; /* 改为百分比以适应不同屏幕宽度 */
  max-width: 800px; /* 最大宽度保持800px */
  height: 400px;
  margin: auto;
  margin-top: 30px;
  overflow: hidden;
}

.image-box img {
  width: 100%;
  height: auto;
}

.profile {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.avatar {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px; /* 与信息盒子之间留点间距 */
}

.info {
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.name {
  font-size: 18px;
  margin-bottom: 10px;
}

.icons {
  display: flex;
}

.icons .icon {
  background-color: gold;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.content-box {
  width: 100%; /* 将宽度设置为100%以与image-box一致 */
  max-width: 800px; /* 将最大宽度设置为800px以与image-box一致 */
  height: 500px; /* 增加高度 */
  margin: auto;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box; /* 使用box-sizing属性确保内边距包含在宽度内 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* 添加阴影效果 */
}

.list {
  list-style: none;
  padding: 0;
}

.list li {
  display: flex;
  align-items: center;
  margin-bottom: 35px; /* 增加上下间距 */
  padding: 10px 0; /* 增加内边距 */
}

.list li img.icon-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>
