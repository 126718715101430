<template>
  <div class="container">
    <div @click="gotoLogin" class="icon-container">
      <img src="../assets/login2.png" alt="非游客" class="icon" />
      <span>非游客</span>
    </div>
    <div @click="gotoIndex" class="icon-container">
      <img src="../assets/login1.png" alt="游客" class="icon" style="" />
      <span>游客</span>
    </div>
    
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { defineOptions } from "vue";

defineOptions({
  name: "HomeView",
});

const router = useRouter();
const gotoLogin = () => {
  router.push({ name: "LoginView" });
};
const gotoIndex = () => {
  router.push({ name: "firstPage" });
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 使容器高度占满整个视口 */
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px; /* 左右间距 */
  cursor: pointer;
}

.icon-container:hover {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
}

.icon {
  width: 50px; /* 根据实际图片大小调整 */
  height: 50px;
  object-fit: contain; /* 保持图片比例 */
}

span {
  font-size: 16px;
  margin-top: 8px;
  color: #333; /* 字体颜色 */
  font-family: Arial, sans-serif; /* 字体 family */
}
</style>