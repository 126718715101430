<template>
  
  <div id="app">
    <!-- 页面内容 -->
    <router-view />

    <!-- 固定图片 -->
    <div v-if="shouldShowIcon" 
         id="fixed-image" 
         class="fixed-icon" 
         @click="jumpAi" 
         title="有什么要问我的嘛~">
      <img src="@/assets/AIphoto.png" alt="AI Photo" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
// 判断是否显示图标
const shouldShowIcon = computed(() => {
  return route.name!== 'HomeView' && route.name!== 'aiPage';
});
const jumpAi = () => {
  router.push({ name: "aiPage" });
};
</script>

<style scoped>
/* 修改后的样式 */
.fixed-icon {
  position: fixed;
  bottom: 100px;    /* 距离底部固定距离 */
  right: 400px;     /* 距离右侧固定距离 */
  z-index: 9999;
  
  /* 移除 transform 相关属性 */
  user-select: none;
  touch-action: none;
}

.fixed-icon img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  
  /* 添加点击动效 */
  transition: transform 0.2s;
}

.fixed-icon img:hover {
  transform: scale(1.1);
}
</style>
